@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

$mobile:991.98px;
$background:#242424;
$blue : rgb(51, 89, 155);

$dark :  #3d3d3d;


body{
  background-color: $background;
  @media(max-width:$mobile) {
    padding:20px;
  }
}
.App {
  text-align: center;
  background-color: $background;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  font-family: Raleway,sans-serif;
  background-color: $background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  img.App-logo{
    animation: float 4s ease-in-out infinite;
  }
  @keyframes float {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
  }


  h1{
    text-transform: uppercase;
    font-weight:900;
    margin-bottom: 0;
    font-size: 2.7em;
    position:relative;
    z-index:1;
  }
  h2{
    margin-top:0;
    font-size: 0.7em;
    
  }
  p.bio{
    font-size:0.7em;
    font-weight:500;
    max-width:calc(100% - 20px);
    width:725px;
  }
  .links a{
    color: white;
    margin: 0 20px;
    font-size: 1.1em;
  }

  .glitch1,.glitch2 {
    position:absolute;
    left:0;
    top:0;
    opacity: .8;
  }
  .glitch1 {
    color: #f0f;
    z-index: -2;
    animation: glitch1 10s cubic-bezier(.25, .46, .45, .94) both infinite;
  }
  .glitch2 {
    color: #0ff;
    z-index: -1;
    animation: glitch2 10s cubic-bezier(.94, .45, .46, .25) both infinite;
  }
  @keyframes glitch1 {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
      1% {
          -webkit-transform: translate(-3px, 3px);
          transform: translate(-3px, 3px)
      }
      2% {
          -webkit-transform: translate(-3px, -3px);
          transform: translate(-3px, -3px)
      }
      3% {
          -webkit-transform: translate(3px, 3px);
          transform: translate(3px, 3px)
      }
      4% {
          -webkit-transform: translate(3px, -3px);
          transform: translate(3px, -3px)
      }
      5% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
  }
  @keyframes glitch2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    1% {
        -webkit-transform: translate(3px, -3px);
        transform: translate(3px, -3px)
    }
    2% {
        -webkit-transform: translate(3px, 3px);
        transform: translate(3px, 3px)
    }
    3% {
        -webkit-transform: translate(-3px, -3px);
        transform: translate(-3px, -3px)
    }
    4% {
        -webkit-transform: translate(-3px, 3px);
        transform: translate(-3px, 3px)
    }
    5% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
  }

  @media (max-width:$mobile){
    padding:20px;
    .menu{
      display:none;
    }
    .App-logo{
      display:none;
    }
    .bio{
      font-size: 1em !important;
    }
  }

  .menu button {
    color: white;
    background: none;
    border: 0;
    margin: 0 8px;
    padding: 5px 0;
    font-family: 'Raleway';
    font-weight: bold;
    cursor:pointer;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: 0.3s;
    z-index:1;
    position:relative;
    &.active{
      border-top:2px solid white;
      border-bottom:2px solid white;
    }
    &:hover{
      .menuglitch1 {
        animation: menuglitch1 10s cubic-bezier(.25, .46, .45, .94);
      }
      .menuglitch2 {
        animation: menuglitch2 10s cubic-bezier(.94, .45, .46, .25);
      }
    }
    &:focus{
      outline:0;
    }


    .menuglitch1,.menuglitch2 {
      position:absolute;
      left:0;
      top:5px;
      opacity: .8;
    }
    .menuglitch1 {
      color: #f0f;
      z-index: -2;
      
    }
    .menuglitch2 {
      color: #0ff;
      z-index: -1;
    }
    @keyframes menuglitch1 {
        0% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
        1% {
            -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px)
        }
        2% {
            -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px)
        }
        3% {
            -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px)
        }
        4% {
            -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px)
        }
        5% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
    }
    @keyframes menuglitch2 {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
      1% {
          -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px)
      }
      2% {
          -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px)
      }
      3% {
          -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px)
      }
      4% {
          -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px)
      }
      5% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
    }
  }

  .links a{
    position:relative;
    z-index: 1;
    &:hover{
      .linkglitch1 {
        animation: linkglitch1 10s cubic-bezier(.25, .46, .45, .94);
      }
      .linkglitch2 {
        animation: linkglitch2 10s cubic-bezier(.94, .45, .46, .25);
      }
    }


    > div {
      position: absolute;
      left: -12px;
      top: 0;
    }
    .linkglitch1 {
      color: #f0f;
      z-index: -2;
      
    }
    .linkglitch2 {
      color: #0ff;
      z-index: -1;
    }
    @keyframes linkglitch1 {
        0% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
        1% {
            -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px)
        }
        2% {
            -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px)
        }
        3% {
            -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px)
        }
        4% {
            -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px)
        }
        5% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
    }
    @keyframes linkglitch2 {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
      1% {
          -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px)
      }
      2% {
          -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px)
      }
      3% {
          -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px)
      }
      4% {
          -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px)
      }
      5% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
    }
  }
}

.pageDotsContainer {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
  z-index:2;

  .dot{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid white;
    margin: 12px auto;
    &.active{
      width: 16px;
      height: 16px;
      background: white;
      margin:-3px auto;
    }
  }
}

.menuBar{
  width:100%;
  height:80px;
  position:fixed;
  top:0;
  left:0;
  z-index:2;
  opacity:0;
  .background{
    width: 100%;
    height: 80px;
    background: $background;
    position: absolute;
    box-shadow: 0 24px 20px 0px $background;
  }
  &.initialized {
    opacity:1;
  }
  &.hide{
    animation: hide 0.4s ease-out;
    animation-fill-mode: forwards;

    @media(max-width:$mobile) {
      .smallLogo{
        animation: gobig 0.3s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
  &.show{
    animation: show 0.4s ease-out;
    animation-fill-mode: forwards;

    @media(max-width:$mobile) {
      .smallLogo{
        animation: gosmall 0.3s ease-out;
        animation-fill-mode: forwards;
      }
    }
  }
  @keyframes hide {
    0% {
      transform:translate(0, 0);
    }
    to {
      transform:translate(0, -80px);
    }
  }
  @keyframes show {
    0% {
      transform:translate(0, -80px);
    }
    to {
      transform:translate(0, 0);
    }
  }

  @keyframes gobig {
    0% {
      top:20px;
      height:60px;
    }
    to {
      top:60px;
      height:170px;
    }
  }
  @keyframes gosmall {
    0% {
      top:60px;
      height:170px;
    }
    to {
      top:20px;
      height:60px;
    }
  }

  @media(max-width:$mobile) {
    opacity:1;
    transform:translate(0, 0);
    &.hide{
      animation: none;
    }
    &.show{
      animation: none;
    }
  }
  .smallLogo{
    height:60px;
    position:absolute;
    top:20px;
    left:20px;
    @media (max-width: $mobile) { 
      left:50%;
      transform:translate(-50%, 0);
     }
  }
  .mobile-menu-button{
    display:none;
    position:absolute;
    top: 16px;
    left: 30px;
    font-size: 2.2em;
    color: white;
    @media (max-width: $mobile) { 
      display:block;
     }
     h3{
      font-size: 0.5em;
      display: inline-block;
      position: relative;
      top: -6px;
      margin: 0;
     }
  }
  .menuContainer{
    margin-top:40px;
    @media (max-width: $mobile) { 
      display:none;
     }
    li {
      display:inline;
      color: white;
      background: none;
      border: 0;
      margin: 0 8px;
      padding: 5px 0;
      font-family: 'Raleway';
      font-weight: bold;
      cursor:pointer;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      transition: 0.3s;
      z-index:1;
      position:relative;
      a {
        color:white;
        text-decoration:none;
      }
      &.active{
        border-top:2px solid white;
        border-bottom:2px solid white;
      }
      &:hover{
        .menuglitch1 {
          animation: menuglitch1 10s cubic-bezier(.25, .46, .45, .94);
        }
        .menuglitch2 {
          animation: menuglitch2 10s cubic-bezier(.94, .45, .46, .25);
        }
      }
      &:focus{
        outline:0;
      }
  
  
      .menuglitch1,.menuglitch2 {
        position:absolute;
        left:0;
        top:5px;
        opacity: .8;
      }
      .menuglitch1 {
        color: #f0f;
        z-index: -2;
        
      }
      .menuglitch2 {
        color: #0ff;
        z-index: -1;
      }
      @keyframes menuglitch1 {
          0% {
              -webkit-transform: translate(0);
              transform: translate(0)
          }
          1% {
              -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px)
          }
          2% {
              -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px)
          }
          3% {
              -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px)
          }
          4% {
              -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px)
          }
          5% {
              -webkit-transform: translate(0);
              transform: translate(0)
          }
      }
      @keyframes menuglitch2 {
        0% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
        1% {
            -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px)
        }
        2% {
            -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px)
        }
        3% {
            -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px)
        }
        4% {
            -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px)
        }
        5% {
            -webkit-transform: translate(0);
            transform: translate(0)
        }
      }
    }
  }
}

#Skills, #Experience, #Projects, #Resume, #Contact {
  font-family: 'Raleway', sans-serif;
  background-color: rgb(36, 36, 36);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  p{
      font-size:0.7em;
      font-weight:500;
      max-width:calc(100% - 20px);
      width:725px;
  }
  @media(max-width:$mobile) {
    padding:20px;
    p{
      font-size:1em;
    }
    a{
      font-size:1em;
    }
  }
}

#Resume a{
  border:2px solid white;
  font-weight:bold;
  color:white;
  padding:10px 20px;
  text-decoration:none;
  font-size:0.7em;
  border-radius: 70px;
  @media(max-width:$mobile) {
      font-size:1em;
  }

}
#Contact form {
  max-width:470px;
  width:95%;
  p.error{
    font-size: 0.6em;
    color: #ff5050;
    font-weight: bold;
    margin-top: 0;
  }
  .row{
    margin:0 -10px;
    height:35px;
    margin-bottom:5px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgb(202, 202, 202);
      opacity: 1; /* Firefox */
    }
    input, textarea{
      margin:0 5px;
      width:calc(100% - 20px);
      height:30px;
      border:0;
      padding:0px 8px;
      font-family:inherit;
      background:none;
      border:2px solid white;
      box-sizing: border-box;
      caret-color:white;
      color:white;
      &[type="submit"]{
        font-weight:bold;
        cursor:pointer;
        &:disabled{
          color:rgb(177, 177, 177);
          border:2px solid rgb(177, 177, 177);
          cursor: default;
        }
      }
      &.error{
        border:2px solid #ff5050;
      }
    }
    
    .half{
      width:calc(50% - 15px);
    }
  }
  .tall{
    height:100px;
    padding:5px 0;
    textarea{
      padding:5px 8px;
      height:100%;
      font-family:inherit;
    }
  }
  @media (max-width:$mobile){
    input, textarea{
      font-size:1em;
      margin:4px 0 !important;
    }
    textarea{
      margin: 0 !important;
    }
    .row{
      height:auto;
      margin:0 !important;
    }
    .tall{
      height:140px;
    }
    .half{
      width:calc(100% - 20px) !important;
    }
    input{
      height:36px !important;
    }
  }
}
#Contact > a{
  border:2px solid white;
  font-weight:bold;
  color:white;
  padding:10px 20px;
  text-decoration:none;
  font-size:0.7em;
  border-radius: 70px;
}

#Intro{
  
  @media(min-width:$mobile) {
    .mobile-only{
      display:none;
      
    }
  }
  @media(max-width:$mobile) {
    .desktop-only{
      display:none;
      
    }
    h1{
      line-height:.95em;
      font-size:3.5em;
      margin-top:175px;
    }
    h2{
      font-size:0.93em;
      margin-bottom:14px;
    }
    .links a{
      font-size:2em;
      margin:25px;
    }
  }
}

#Contact .links a{
  position:relative;
  z-index: 1;
  color: #ffffff;
  margin: 0 20px;
  font-size: 1.1em;
  @media (max-width:$mobile){
    font-size:1.5em;
    margin:25px;
  }
  &:hover{
    .linkglitch1 {
      animation: linkglitch1 10s cubic-bezier(.25, .46, .45, .94);
    }
    .linkglitch2 {
      animation: linkglitch2 10s cubic-bezier(.94, .45, .46, .25);
    }
  }


  > div {
    position: absolute;
    left: -12px;
    top: 0;
  }
  .linkglitch1 {
    color: #f0f;
    z-index: -2;
    
  }
  .linkglitch2 {
    color: #0ff;
    z-index: -1;
  }
  @keyframes linkglitch1 {
      0% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
      1% {
          -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px)
      }
      2% {
          -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px)
      }
      3% {
          -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px)
      }
      4% {
          -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px)
      }
      5% {
          -webkit-transform: translate(0);
          transform: translate(0)
      }
  }
  @keyframes linkglitch2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    1% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px)
    }
    2% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px)
    }
    3% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px)
    }
    4% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px)
    }
    5% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
  }
}

#Experience{
  padding-top:115px;
  svg {
    margin-left: 0 !important;
    margin-right: 0 !important;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
  }
  @media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
      text-align:left;
    }
    .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content .vertical-timeline-element-date {
      text-align: right;
    }
  }

  svg.fa-graduation-cap{
    margin-left:-15px;
  }

  svg.fa-js-square{
    margin-left:-11px;
  }

  svg.fa-unity{
    margin-left:-14px;
  }

}

#Projects{
  padding-top:115px;
  .projects-content{
    width:95%;
    max-width:1170px;
    margin:0 auto;
    position:relative;
    padding:2em 0;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: minmax(280px, 1fr);
    grid-gap: 1rem;
    grid-auto-flow: dense;
    > li {
      background:grey;
      list-style-type: none;
      border-radius: 16px;
      position:relative;
      overflow:hidden;
      img{
        width:100%;
        position:absolute;
        height:auto;
        left:0;
        top:50%;
        transform:translate(0,-50%);
        border-radius:16px;
      }
      .details{
        position:absolute;
        width:100%;
        height:100%;
        box-sizing:border-box;
        opacity:0;
        z-index:1;
        background: rgba(0,0,0,0.7);
        border-radius: 16px;
        color:white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: opacity 0.4s;
        padding:20px;
        &:hover{
          opacity:1;
          transition: opacity 0.4s;
        }
        .title{
          font-weight:700;
          font-size:0.8em;
        }
        .info{
          font-size:0.7em;
          margin: 8px 0 28px 0;
        }
        a{
          font-weight: 500;
          border: 2px solid white;
          border-radius: 40px;
          font-size: 0.6em;
          text-decoration: none;
          color: white;
          padding: 3px 10px;
          cursor:pointer;
        }
        @media(max-width:$mobile) {
          .info{
            font-size:1.2em;
          }
          a{
            font-size:1.1em;
          }
        }
      }
    }
    > li > figure > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

#fp-nav ul li a span {
  background:white !important;
}

#Skills{

  @mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
  }
  .other-skills-heading{
    padding-bottom:10px;
  }
  .skill{
    background:$blue;
    color:white;
    &:nth-of-type(2n) {
      background:lighten($blue , 10% );
    }
    border-radius: 20px;
    display:inline-block;
    font-size: .7em;
    margin: 4px;
    font-weight: bold;
    padding: 5px 10px;
  }
  .wrap {
    max-width: calc(100% - 20px);
    width: 725px;
    margin: 0 auto;
  }
  .bar {
    background:$blue;
    width: 0;
    margin: .25em 0;
    color: #fff;
    position: relative;
     transition:width 2s, background .2s;
    -webkit-transform: translate3d(0,0,0);
     clear: both;
    height:36px;
    border-radius:50px;
     &:nth-of-type(2n) {
       background:lighten($blue , 10% );
     }
     .label {
     font-size: .5em;
     background: $dark;
     width: 8em;
     display: inline-block;
     position: absolute;
     height:100%;
     left: -1px;
     z-index: 2;
     font-weight: bold;
     box-sizing:border-box;
     line-height:36px;
     border-top-left-radius: 50px;
     border-bottom-left-radius: 50px;
      &.light {
       background:#545454;
     }
 
 }
  }
  .count {
    position: absolute;
    right: 0.55em;
    /* top: .6em; */
    /* padding: .15em; */
    font-size: .5em;
    font-weight: bold;
    height: 100%;
    line-height: 36px;
  }

}

.heart{
  font-size:0.6em !important;
}

.bm-overlay{
  width:100vw !important;
  height:100vh !important;
  top:0;
  left:0;
}

.bm-menu-wrap{
  width:60% !important;
  height:100vh !important;
  top: 0;
  left: 0;
  background:#242424;
  .close-menu{
    height:80px;
    color:white;
    text-align:left;
    font-size:1em;
    svg{
      position:absolute;
      top:20px;
      left:30px;
    }
    &:focus{
      outline:0;
      text-align:left;
      color:white;
    }
  }
  a{
    &.active{
      background:$blue;
      border-bottom: 3px solid darken($blue, 10%);
    }
    border-bottom: 3px solid #242424;
    color:white;
    background:none;
    text-decoration: none;
    font-size:1em;
    font-weight:bold;
    text-align:left;
    padding:10px 0 10px 20px;
    &:focus{
      outline:0;
    }
    &:active{

    }
  }
}


.vertical-timeline-element-subtitle {
  font-size:0.6em;
}